body {
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* TODO set color variables
  https://developer.mozilla.org/en-US/docs/Web/CSS/var
  */

  /* Destiny variables */
    /*Rarity */
  --destiny-exotic-gold: #cdae32;
  --destiny-legendary-purple: #4f2e61;
  --destiny-uncommon-grey: #c2bcb5;

    /* Subclasses */
  --destiny-void-purple: #553870;
  --destiny-arc-blue: #7f9eb0;
  --destiny-solar-orange: #d1723c;

    /* Text boxes*/
    --destiny-light-grey-textbox: #585858;
    --destiny-dark-grey-textbox: #292927;
  /* Call of Duty variables */

  /* Default variables */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer-80
{
  margin: 0;
  padding: 0;

  height: 80px;
  width: 100%;
}

.toolkit-icon
{
  background-color: white;
  margin: 2px;
  border-radius: 5px;
  padding: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  vertical-align: middle;
  align-content: center;
  font-weight: 800;
  overflow: hidden;

}

.portfolio-tile-top
{
  border-radius: 5px;
  height: 400px;
  padding: 0px;
  /* margin: 10px; */
}

.portfolio-tile-bottom
{
  background: whitesmoke;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  opacity: 0%


}

.portfolio-tile-bottom:hover
{
  opacity: 100%;
  transition: opacity 250ms ease-in;
}

/* ------------- Navbar CSS ------------------ */
/* Destiny Active Nav-Link */
.navbar .destiny.nav-link.active
{
  color: grey;
}

/* COD Active Nav Link */
.navbar .cod.nav-link.active
{
  color: rgba(68, 153, 186,1);
}

/* Default Active Nav Link */
.navbar  .default.nav-link.active
{
  color: greenyellow;
}

/* Destiny hover Nav-Link */
.navbar .destiny.nav-link:hover
{
  color: grey;
}

/* COD hover Nav Link */
.navbar .cod.nav-link:hover
{
  color: grey;
}

/* Default hover Nav Link */
.navbar  .default.nav-link:hover
{
  color: grey;
}

/* Default Navbar */
.navbar  .default.nav-link
{
  color: white;
  font-family:'Courier New', Courier, monospace;
  font-size: large;
}

/* Destiny Navbar */
.destiny.navbar
{
  background: linear-gradient(0deg,transparent 5%, rgba(2,8,23,.8) 50%, #020817 80%);
}

/* COD Navbar*/
.cod.navbar
{
  background-color: rgba(20,17,15,.5);
}

/* Default Navbar */
.default.navbar
{
  background: linear-gradient(90deg, transparent 0%, rgba(217, 217, 217, .1) 35% 65%, transparent 100%);
  justify-content: center;
}

/* -------------- COD NavTabs ---------------- */
.nav-tabs.cod
{
  background-color: rgba(20,17,15,.5);
  color:white;
  border: transparent;
}

.nav-tabs .cod.nav-link
{
  font-family: Arial, Helvetica, sans-serif;
  color: #bad7de;
  font-weight: bold;
  opacity: .7;

  padding: 10px 20px;
}

.nav-tabs .cod.nav-link:hover
{
  border-bottom-color: #abb0b2;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-style: solid;
  border-radius: 0px;
  
  background: linear-gradient(0deg,  #325667 50%, transparent 95%);
  opacity: 1;
}

.nav-tabs .cod.nav-link.active
{
  border-bottom-color: #abb0b2;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-style: solid;
  border-radius: 0px;

  background: linear-gradient(0deg,  #325667 50%, transparent 95%);

  opacity: 1;
}

#defaultNavbar.show > ul
{
  background: linear-gradient(to bottom, rgba(217, 217, 217, 0.1), transparent 100%), linear-gradient(-45deg, #111111 0%, #071922, #071921, #111111 100%);
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus,
.navbar-toggler
{
    outline: none;
    box-shadow: none;
    border: none;
}

/* Portfolio Side Navigation */
.portfolio-nav > .nav-link
{
  color: white ; 
  font-family: 'Courier New', Courier, monospace;
  font-weight: 500;
  margin-top: 5px;
  text-decoration: none;

}

.portfolio-nav > .nav-link:hover
{
  color: grey;
}



.portfolio-nav > .nav-link.active
{
  color: greenyellow
}

.dropdown-circle{
  border-radius:50%;
  width: 40px;
  height: 40px;
  outline: none;
  box-shadow: none;
  border-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu.circle
{
  background:transparent;
  width: 40px;
  border: none;
}

.dropdown-menu.circle.show
{
  width: 50px;
  border-color: transparent;
}

/* Resume formatting */
.resume
{
  border-radius: 15px;
  padding-left: 0px;
  padding-right: 10px;
}

.resume-header
{
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  width: 100%;
  color: white;
  border-bottom-color: greenyellow;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  margin-bottom: 30px;
  padding-left: 0;
  margin-left: 0;
}

.resume-job-tile
{
  font-family: 'Courier New', Courier, monospace;
  font-size: 18px;
  width: 100%;
  color: white;
  font-weight: 700;

  padding-left: 0;
  margin-left: 0;
}

.resume-job-description
{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: 100%;
  color: white;
  font-weight: lighter;
  font-style: italic;

  margin-left: 0;
  padding-left: 0;
}

.resume-job-achievements > li
{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
}
/* Portfolio Header */
.about-scrollspy > h1
{
  color: white ; 
  font-family: 'Courier New', Courier, monospace;
  font-weight: 300;
  font-size: 26px;
  margin: 5px;
  padding: 5px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
  text-align: center;
}

.about-scrollspy > p
{
  color: white ; 
  font-family: 'Courier New', Courier, monospace;
  font-weight: 300;
  margin-top: 5px;
  font-size: 16px;
  font-style: italic;
}

.about-scrollspy {
  scrollbar-color: white transparent;
  scrollbar-width: thin;
}


/* Destiny CSS */
.destiny-bg {
  background: url("./Images/Generic_Destiny_Site.png") no-repeat center center fixed;

  /* Center and scale the image nicely */
  background-size: cover;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y:scroll;
  background-attachment: fixed;
  width: 100%;
	
  /* Set up positioning */
  position: absolute;
}

.d-nav-text
{
  font-size: larger ;
  color: white; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-h1-sm
{
  font-size: 10vw ;
  color: white ;
  font-weight:bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-h1
{
  font-size: clamp(24px,9.5vw,64px);
  color: white ;
  font-weight:bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-sub-text-sm
{
  font-size: 6vw ;
  color: white ;
  font-weight:lighter;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-sub-text
{
  font-size: 35px ;
  color: white ;
  font-weight:lighter;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-title-description
{
  font-size: clamp(15px,3.7vw,30px);
  color: grey ;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.d-description-sm
{
  font-size: 3vw ;
  color: white ;
  font-style: italic;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-description
{
  font-size: 18px ;
  color: white ;
  font-style: italic;
  font-weight:lighter ;
}

.d-header-sm
{
  font-size: 3vw ;
  color: white;
  font-weight:bold ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-header
{
  font-size: 18px ;
  color: white;
  font-weight:bold ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.d-large-header-sm
{
  font-size: 6vw ;
  color: white ;
  font-weight:bold ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.d-large-header
{
  font-size: 32px ;
  color: white ;
  font-weight:bold ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.d-general-sm
{
  font-size: 4vw ;
  color: white ;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.d-general
{
  font-size: 18px ;
  color: white ;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}


.d-sub-title
{
  font-size: 18px ;
  color: white ;
  text-transform: uppercase;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.d-sub-title-sm
{
  font-size: 4vw ;
  color: white ;
  text-transform: uppercase;
  font-weight:lighter ;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.destiny-progress
{
  height: 30px;
  border-radius: 0px;
  background-color: #272727;
}

/* Destiny Inventory Page CSS */
.destiny-inventory-page-selector
{
  background-color: transparent;
  aspect-ratio: 1/1;
  border-style:"solid";
  border-width:1px;
  border-color: white;
  border-radius:0px;
  color: white;
  white-space: "normal";

  font-size: 48px;
  width: 100%;  
}

.destiny-inventory-page-selector:hover
{
  border-color: white;
  color: white;
  background-color: rgba(255, 255, 255, .4);
  opacity: 1;
}
.destiny-inventory-page-selector.collapsed
{
  opacity: .4;
}

.destiny-inventory-page-selector:not(.collapsed) 
{
  
  background-color: rgba(255, 255, 255, .4);
  animation: destinySelectorFadeIn .5s;
}

@keyframes destinySelectorFadeIn {
  0% { opacity: .4; 
      }
  100% { opacity: 1; }
}

.emptyInventorySpace
{
  padding: 5px;
  /* margin: 10px; */
  aspect-ratio: 1;

  
}

.emptyInventorySpace > div
{
  width: 100%;
  height: 100%;
  background: transparent;

  /* Corner borders
   https://css-shape.com/corner-only/ */
   --s: 20px; /* size of the corners */
   border: 1px solid rgba(255, 255, 255, .5); 
   background: transparent content-box;
   mask: 
     conic-gradient(#000 0 0) content-box,
     conic-gradient(at var(--s) var(--s),#0000 75%,#000 0) 
     0 0/calc(100% - var(--s)) calc(100% - var(--s));
}

.modal.fade.show {
  background-color: rgba(255,255,255,.4);
}

.destiny.modal-body
{
  scrollbar-width:thin;
}

.destiny.book.modal-header
{
  background-color: var(--destiny-light-grey-textbox);
  color:white;
  text-transform: uppercase;
  font-size: 30px;
  
}

.destiny.book.modal-body
{
  background-color: var(--destiny-dark-grey-textbox);
  color:white;
  font-style: italic;
  
}

.destiny.book.modal-footer
{
  background-color: var(--destiny-light-grey-textbox);
  color:white;
  border-top-width: 1px;
}

.inventorySpace
{
  padding: 5px;
  /* margin: 10px; */
  aspect-ratio: 1;

 
}

.inventorySpace > div
{
  width: 100%;
  height: 100%;

  border-color: white;
  border-width: 3px;
  border-style: solid;
}

.inventorySpace.legendary > div 
{
  background: var(--destiny-legendary-purple);
}


.inventorySpace.exotic > div 
{
  background: var(--destiny-exotic-gold);

}

.inventorySpace.rare > div 
{
  background: lightblue;

}

.inventorySpace.uncommon > div 
{
  background: var(--destiny-uncommon-grey);


}

.cod-bg {
  /* background-color: #14110F; */
  /* height: 100vh; */

  

  /* Center and scale the image nicely */
  background: linear-gradient(rgba(31,39,58,.8),rgba(31,39,58,.8));
  background-size: cover;
  height: 100%;
  background-position: center;  
  background-repeat: no-repeat;
  overflow-y:scroll;
  background-attachment: fixed;
  width: 100%;
	
  /* Set up positioning */
  position: fixed;
  /* z-index: -5; */

  
}

.cod-bg-img-container
{
  background: url("./Images/Warzone\ Drop\ Clouds.png");
  background-repeat: no-repeat;
  background-position: center;  
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  /* max-width: 125%;
  max-height: 150%; */
  min-width: 100%; 
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;

  /* z-index: -3; */

}


.cod-bg-img
{
  /* https://css-tricks.com/tinted-images-multiple-backgrounds/ */
  content: url("./Images/Warzone\ Drop\ Hands.png");
  /* background: url("./Images/Warzone\ Drop\ Hands.png"); */
  filter: blur(8px);
  /* background-blend-mode: multiply; */
  /* background-repeat: no-repeat;
  background-position: center;  
  background-attachment: fixed;
  background-size: cover; */
  
  /* Sets up background animation */
  animation: codBgRotate 3s;
  animation-iteration-count: infinite;
  
  margin: auto;

  /* z-index: -1; */
  position: absolute;
  /* z-index: -2; */
  /* min-height: 120%; */
  width: 120%;
  max-width: 150%;
  height: auto;

  left: -10%;
  right: -10%;
  top: -10%;
  bottom: -10%
}

@keyframes codBgRotate {
  0%
  {
    transform: rotate(1deg);
  }

  25%
  {
    transform: rotate(-1deg);
  }

  50%
  {
    transform: rotate(-1deg);
  }

  100%
  {
    transform: rotate(1deg);
  }
}

.dotPattern {
  background-image: repeating-linear-gradient(-45deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 6px);
  background-size: 4px 4px;
}

.codExpButton
{
  background-color: grey;
  background:linear-gradient(to right, rgba(17,18,22,.7), rgba(27,52,59,.8) 25% 75%, rgba(17,18,22,.7));

  padding:0;

  height: 8vh;
  min-height: 50px;
  margin-top:2vh;
  width:75%;
  border:none;
  text-align:left;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  
}

.codExpButton:hover
{
  border-top-color: #82d3f4;
  border-top-width: 2px;
  border-top-style: solid;

  box-shadow: 5px 4px 6px -1px rgba(130, 211, 244,.35);
}

.codExpButton > .text
{
  color:#82d3f4;
  font-size:20px;
  font-family:Helvetica;
  padding-left: 20px;
  padding-right: 20px;
  display:flex;
  align-items:center;
  justify-content: space-between;
}

.codExpButtonSm
{
  background-color: transparent;
  padding:0;
  margin: 0;
  min-height: 50px;
  margin-top:2vh;
  border:none;
  text-align:center;
  display:flex;
  align-items:center;
}

.codExpButtonSm > .button
{
  color:#82d3f4;
  font-size:20px;
  padding-left: 0;
  margin: 0;
  display:flex;
  align-items:center;
  justify-content: end;
}

.codExpButtonOverlay
{
  background-size: 4px 4px;
  background-image:
    linear-gradient(to right, rgba(0,0,0,.08) 1.5px, transparent 1.5px),
    linear-gradient(to bottom, rgba(0,0,0,.08) 1.5px, transparent 1.5px);

  width: 100%;
  height: 100%
}

.bookCard
{
  padding:20px;
  min-height: 100%;
}

.bookCard:hover .codBookImg{
  opacity: 1;
}

.codBookImg{
  opacity: .4;
}


.codBookTitleBackground {
  min-height: 50px; 
  height: max-content;
  max-height: 100px;
  width: 100%;
  background: #14110F;
  /* padding-left: 10px; */
}

.codBookTitle {
  color: white;
  display: flex;
  align-items: center;
  font-weight:300;
}

.bookCard:hover .codBookTitleBackground{
  /* height: 50px;  */
  background: linear-gradient(0deg in hsl shorter hue, rgba(68, 153, 186, 1) 0% 5%, rgba(106,194,228) 15%, rgba(68, 153, 186,.2) 50%, #14110F 85%);
  /* padding-left: 20px;
  display: flex;
  align-items: center;
  color: white;                  */
}
.default-bg {
  background: linear-gradient(-45deg,#111111 0%, #0B061B 12%, #0C061B 20%, #0A0B1C 34%, #071922 75%, #071921 83%, #111111 100%),
  linear-gradient(45deg, #111111 2%, #0B061B 14%, #0C061B 20%,  #CFB4FF 35%, #B4E6FF 58%, #071922 75%, #071921 83%, #111111 100%);
  height: 100%;
  width: 100%;
  /* Full height */
  /* height: 100vh; */
  background-size: cover;
  background-attachment: fixed;
}

.default-subheader
{
  color: white;
  text-transform: uppercase;
  font-family: 'Courier New', Courier, monospace;
  font-weight: lighter;
  font-size:xx-large;
}

.default-description-header
{
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight: normal;
  text-transform: uppercase;
  font-size:large;
  padding-bottom: 15px;
}

.default-base-text
{
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight:normal;
  font-size:medium;
  text-transform: none;
}

.skill-label
{
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight: lighter;
  font-size:16px;
}

.default-rounded
{
  background: linear-gradient(45deg,rgba(255,255,255,.4),  rgba(255,255,255,.7) 30% ,  rgba(255,255,255,.4) 70%) , linear-gradient(-45deg,rgba(255,255,255,.2),  rgba(255,255,255,.5) 30%, rgba(255,255,255,.2) 70%);
  border-radius: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: small;
  word-break: break-word;
}

.skill-radio-button
{
  background-color: rgba(255, 255, 255, .1);
  width: fit-content
}

.skill-radio-button:hover
{
  background-color: rgba(255, 255, 255, .8) !important;
  color:#020817;
}

.skill-radio-button.checked
{
  background-color: white !important;
  color:#020817;
}

.green-line
{
  background: linear-gradient(90deg, green, greenyellow , transparent 30%);
  height: 3px;
}

.portfolio-skill-label
{
  background-color: rgb(71, 81, 92);
  color: white;
  border-radius: 20px;
  font-size: smaller;
  padding: 5px 8px;
  text-align: center;
  white-space: nowrap;
  width: fit-content
}

.project-card
{
  background-color: rgba(255, 255, 255, .1);
  height: 150px;
  border-radius: 5px;
}

.project-card, .header
{
  font-weight: lighter;
  font-family: monospace;
  font-size: large;
  color: white;
  text-align: center;
}

.project-card, .number
{
  font-weight: bolder;
  font-family: monospace;
  font-size: 40px;
  color: white;
  text-align: center;
}

.top-margin-10
{
  margin-top: 10vh;
}

.top-margin-5
{
  margin-top: 5vh;
}

.top-margin-20
{
  margin-top: 20vh;
}
/* Removes Scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar
{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar
{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.horizontalScroll
{
  overflow-x: scroll;
  display: flex;
}

/* Fonts */

.ibm-plex-sans-condensed-thin {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-condensed-extralight {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-condensed-light {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-condensed-regular {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-condensed-medium {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-condensed-semibold {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-condensed-bold {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-sans-condensed-thin-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-sans-condensed-extralight-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-sans-condensed-light-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-sans-condensed-regular-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-sans-condensed-medium-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-sans-condensed-semibold-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-sans-condensed-bold-italic {
  font-family: "IBM Plex Sans Condensed", serif;
  font-weight: 700;
  font-style: italic;
}
